.sliders {
  padding-top: 7.2rem;
  background: linear-gradient(
    to bottom,
    #ffffff 0%,
    #ffffff 50%,
    #b9d088 50%,
    #b9d088 100%
  );
}

.swiper-container {
  padding: 0 10rem 16.3rem;
}
.sliders .swiper-slide {
  padding: 1rem 0;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 15px;
}
.service_navigate {
  position: relative;
}
.service_navigate .swiper-button-next {
  right: -8rem !important;
  left: auto;
  top: 250px;
}
.service_navigate .swiper-button-prev {
  left: -8rem !important;
  right: auto;
  top: 250px;
}
.service_navigate .swiper-button-next,
.service_navigate .swiper-button-prev {
  position: absolute;
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
}

.slider-images {
  border-radius: 10px;
}
.swiper-slide .slider-images img {
  width: 100%;
}
.slider-content {
  padding: 0 1rem;
}
.slider-details {
  text-align: left;
  padding: 0 1rem;
  height: 230px;
}
.slider-details h3 {
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.3rem;
  color: #333333;
  padding-top: 3rem;
  padding-bottom: 1.5rem;
}
.slider-details p {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 27.23px;
  color: #666666;
  opacity: 0.9;
  padding-bottom: 2rem;
}
/* .swiper-button-next{
    left: 80%;
}
.swiper-button-prev{
    right: 50%;
} */

.swiper-button-next,
.swiper-button-prev {
  background-color: var(--white);
  padding: 3rem;
  /* right: -20px; */
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: #ffffff;
  border: 1px solid #cccccc;
  color: var(--gray-10) !important;
  fill: var(--gray-10) !important;
  stroke: black !important;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 20px;
  font-weight: 800;
}
@media screen and (max-width: 768px) {
  .sliders {
    padding-top: 6rem;
}
  .service_navigate .swiper-button-next {
    right: 7rem !important;
    left: auto;
    top: 53rem;
  }
  .service_navigate .swiper-button-prev {
    left: 7rem !important;
    right: auto;
    top: 53rem;
  }
  .swiper-container {
    padding: 0 4rem 16.3rem;
  }
}
