.finance_trade {
  margin-top: 10rem;
}
.finance_trade_wrapper {
  padding: 9rem 15.2rem;
  background: #eef3e4;
  border-radius: 40px;
}
.finance_trade_content {
  padding-top: 11rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
}
.service {
  background: #fdfdff;
  border-top: 4px solid #9abb50;
  border-radius: 1rem;
  padding: 4.7rem 3.1rem;
}
.service_img {
  width: 4.6rem;
  height: 4.6rem;
  background: #f0f1ec;
  border-radius: 50%;
}
.service_img img{
 padding: 1.1rem;
}
.service h1 {
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.3rem;
  color: #333333;
  padding: 4rem 0 1.8rem 0;
}
.service p {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2.3rem;
  color: #666666;
}
@media screen and (max-width: 768px) {
  .finance_trade_content {
    padding-top: 5rem;
    grid-template-columns: 1fr;
   
  }  
  .finance_trade_wrapper {
    padding: 4rem 4.3rem;
    background: #eef3e4;
    border-radius: 40px;
}
.service {
  background: #fdfdff;
  border-top: 4px solid #9abb50;
  border-radius: 1rem;
  padding: 4.7rem 2.6rem;
}

}