.location_map{
    padding-top: 15.8rem;
}

.location_map_wrapper{
    width: 70rem;
    margin: 0 auto;
    height: 40rem;

}
.location_map_wrapper iframe {
    width: 100%;
    height: 40rem;
    border: none;

}

@media (max-width: 768px) {
    .location_map_wrapper{
        width: 100%;
        margin: 0 auto;
        height: 40rem;
        padding: 2rem;
    }

}