.careers {
  padding-top: 10rem;
}
.career_wrapper {
  border: 1px solid #333333;
  border-radius: 3rem;
  padding: 4rem;
  margin-bottom: 3rem;
}
.circular_apply {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.circular h1 {
  font-weight: 700;
  font-size: 3rem;
  line-height: 3.4rem;
  color: #282c4b;
}
.circular h3 {
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.1rem;
  color: #666666;
  padding: 2rem 0 3.8rem 0;
}
.circular_info {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}
.circular_info p {
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.1rem;
  text-align: center;
  color: #333333;
  padding: 1rem;
  border-radius: 3rem;
  border: 1px solid #333333;
}
.apply p {
  cursor: pointer;
  font-weight: 400;
  font-size: 2.5rem;
  line-height: 2.9rem;
  color: #9abb50;
}
.apply img {
  padding-left: 1.5rem;
}
@media screen and (max-width: 768px) {
  .circular h1 {
    font-weight: 700;
    font-size: 1.7rem;
    line-height: 2rem;
    color: #282c4b;
  }
  .career_wrapper {
    border: 1px solid #333333;
    border-radius: 3rem;
    padding: 3rem 2rem;
    margin-bottom: 3rem;
  }
  .circular h3 {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2.3rem;
    color: #666666;
    padding: 2rem 0 3.8rem 0;
    max-width: 27.8rem;
  }
  .circular_info {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 2rem;
  }

  .circular_info p {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.6rem;
    text-align: center;
    color: #333333;
    padding: 1rem;
    border-radius: 3rem;
    border: 1px solid #333333;
  }
 .circular_info .job_time {
    max-width: 15rem;
  }
 .circular_info .vacancies {
    max-width: 10.7rem;
  }
  .apply p {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.8rem;
    color: #9abb50;
  }
  .apply img {
    width: 2rem;
    padding-left: 1rem;
  }
}
