.contact_form {
  padding-top: 20rem;
}

.contact_form_wrapper {
  background-image: url("../../../../Assets/images/form-bg.png");
  background-repeat: no-repeat;
  background-size: 198px 275px;
  padding: 9.5rem 8.5rem;
  display: flex;
  flex-wrap: wrap;
}
.form_title {
  height: 63.2rem;
  position: relative;
}
.form_content {
  position: absolute;
  top: 0;
  padding: 9.2rem 4.5rem 0 5.1rem;
}
.form_content h1 {
  font-weight: 700;
  font-size: 50px;
  line-height: 57px;
  text-transform: uppercase;
  color: #ffffff;
}
.form_content p {
  font-weight: 400;
  font-size: 15px;
  line-height: 17px;
  color: #ffffff;
  padding-top: 3rem;
}
.form_title img {
  width: 100%;
  height: 100%;
}
.form_wrapper {
  padding: 4rem 8.1rem;
  width: 60%;
  border: 1px solid #cccccc;
  border-radius: 0px 15px 15px 0px;
}
.form_wrapper input[type="text"],
.form_wrapper input[type="number"],
.form_wrapper input[type="email"] {
  width: 100%;
  padding: 1.2rem 0;
  margin: 0.8rem 0;
  border-bottom: 2px solid #cccccc;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2.4rem;
}
.form_wrapper input[type="text"]:focus,
.form_wrapper input[type="email"]:focus,
.form_wrapper input[type="number"]:focus,
textarea[type="text"]:focus {
  border-bottom: 2px solid #9abb50;
  color: #222a41;
}
textarea[type="text"] {
  width: 100%;
  height: 15.8rem;
  padding: 1.2rem 0;
  margin: 0.8rem 0;
  border-bottom: 2px solid #cccccc;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2.4rem;
}
.form_wrapper .btn {
  margin-top: 3rem;
}
.form_btn {
  text-align: right;
}
@media screen and (max-width: 768px) {
  .form_title img {
    border-radius: 15px 15px 0px 0px;
  }
  .contact_form_wrapper {
    padding: 3rem 2rem 0 2rem;
    flex-direction: column;
  }
  .form_wrapper {
    padding: 6rem 2rem;
    width: 100%;
    border: 1px solid #cccccc;
    border-radius: 0 0 15px 15px;
  }
  .form_title {
    height: 53.7rem;
    position: relative;
  }
  .form_content h1 {
    font-weight: 700;
    font-size: 4rem;
    line-height: 4.6rem;
    text-transform: uppercase;
    color: #ffffff;
  }
  .form_content {
    position: absolute;
    top: 0;
    padding: 7.8rem 3.9rem 0 4.3rem;
  }
  .contact_form .container {
    padding: 0;
  }
}
