.container {
  --containerWidth: 143.6rem;
  --containerPadding: 2.4rem;
  max-width: calc(var(--containerWidth) + (var(--containerPadding) * 2));
  padding: 0 var(--containerPadding);
  margin: auto;
}
html {
  font-size: 62.5%;
}

.title_wrapper .center {
  text-align: center;
}

.title {
  font-family: "Ubuntu";
  font-weight: 700;
  font-size: 5rem;
  line-height: 5.3rem;
  color: #282c4b;
}
.desc {
  font-weight: 400;
  font-size: 2rem;
  line-height: 3.2rem;
  color: var(--gray-6);
}
.btn {
  background: var(--green-1);
  padding: 2.3rem 5.4rem;
  border: 2px solid var(--white);
  box-shadow: 0px 30px 50px rgba(154, 187, 80, 0.15);
  border-radius: 192px;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.1rem;
  text-align: center;
  letter-spacing: 0.02em;
  cursor: pointer;
  color: var(--white);
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}
.btn:hover{
  background-color:#1A1A1A;
  color: var(--white);
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
@media screen and (max-width: 768px) {
  .title {
    font-weight: 700;
    font-size: 3.2rem;
    line-height: 3.7rem;
  }
  .desc {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2.3rem;
    color: var(--gray-6);
  }
}
