.our-service .desc{
    padding-top: 2.8rem;
}
.slider-back{

    position: absolute;
    left: -1px;
    top: 476px;
    height: 237px;
    background: #B9D088;
}
