.contact_us {
  padding-top: 16.5rem;
  position: relative;
}
.contact_us_content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.contact_us_info h3 {
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.3rem;
  color: #333333;
}
.contact_us_info {
  width: 50%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 8rem;
  position: relative;
}
.address {
  padding-left: 4rem;
  position: relative;
}
.contact_us_info .details:not(:last-child)::before {
  content: "";
  position: absolute;
  top: 2rem;
  left: 1.5rem;
  width: 2px;
  height: 13rem;
  background: #ebebeb;
}
.contact_us_info p {
  font-weight: 400;
  font-size: 1.9rem;
  line-height: 2.2rem;
  color: #666666;
  padding-top: 2rem;
}
.phone::before {
  content: "";
  position: absolute;
  top: 0.6rem;
  left: 1rem;
  border-radius: 50%;
  width: 1.2rem;
  height: 1.2rem;
  background-color: #505050;
}
.contact_us_form {
  max-width: 72rem;
  position: absolute;
  top: -18.4rem;
  right: 2%;
  padding: 4rem;
  width: 50%;
  background-color: #ffffff;
  border: 1px solid #e9e9e9;
  box-shadow: 20px 24px 60px rgba(0, 0, 0, 0.05);
}
.contact_us_form h1 {
  font-weight: 700;
  font-size: 3rem;
  line-height: 3.4rem;
  color: #282c4b;
  padding-bottom: 4.5rem;
}
.contact_us_form input[type="text"],
.contact_us_form input[type="number"],
.contact_us_form input[type="email"],
.contact_us_form textarea[type="text"] {
  width: 100%;
  padding: 1.2rem 2.1rem;
  margin: 1.5rem 0;
  border: 2px solid #cccccc;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2.4rem;
}
.contact_us_form input[type="text"]:focus,
.contact_us_form input[type="email"]:focus,
.contact_us_form input[type="number"]:focus,
.contact_us_form textarea[type="text"]:focus {
  border: 2px solid #9abb50;
  color: #222a41;
}
.contact_form_btn .btn {
  margin-top: 4rem;
}
@media screen and (max-width: 768px) {
  .contact_us_content {
    flex-direction: column;
  }
  .contact_us_info {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 8rem;
  }
  .contact_us_form {
    max-width: 100%;
    position: static;
    margin-top: 10rem;
    padding: 4rem;
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #e9e9e9;
  }
  .contact_us_form h1 {
    font-weight: 700;
    font-size: 1.7rem;
    line-height: 2rem;
    color: #282c4b;
    padding-bottom: 2.5rem;
  }
  .contact_us_info h3 {
    font-weight: 500;
    font-size: 1.7rem;
    line-height: 2rem;
    color: #333333;
  }

  .contact_us_info p {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2.8rem;
    color: #666666;
    padding-top: 1.2rem;
  }
}
