.banner_top {
  position: relative;
  width: 100%;
  height: 56rem;
}

.banner_top::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
}

.banner_top img {
  width: 100%;
  height: 56rem;
  object-fit: cover;
  object-position: center bottom;
}

.banner_top_content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.banner_top_content .container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.banner_title {
  font-weight: 700;
  font-size: 5.8rem;
  line-height: 6.7rem;
  text-transform: capitalize;
  color: var(--white);
}

.banner_sub_title {
  font-weight: 400;
  font-size: 2rem;
  line-height: 3.8rem;
  color: var(--gray-1);
  margin-top: 2rem;
}
@media screen and (max-width: 768px) {
  .banner_title {
    font-weight: 700;
    font-size: 3.8rem;
    line-height: 4.4rem;
    text-transform: capitalize;
    color: var(--white);
  }
  .banner_sub_title {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.9rem;
    color: var(--gray-1);
    margin-top: 1rem;
  }
}