.footer {
  margin-top: 17rem;
  background: #1f1f1f;
  padding: 2.3rem;
}
.footer_content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.footer_content p {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: #ffffff;
}
.footer_center img {
  margin-right: 1rem;
}
.footer_left img {
  margin-right: 0.5rem;
}
.antopolis {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: #ffffff;
}
@media (max-width: 767.98px) {
  .footer_content {
    padding: 3.5rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2.5rem;
    align-items: center;
  }
}
