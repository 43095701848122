.company_licence {
  padding-top: 18.3rem;
}
.company_licence_wrapper {
  padding-top: 13rem;
}
.company_licence_navigate {
  position: relative;
}
.company_licence_navigate .swiper-button-next {
  right: 8rem !important;
  left: auto;
  top: 23.3rem;
}
.company_licence_navigate .swiper-button-prev {
  left: 6rem !important;
  right: auto;
  top: 23.3rem;
}
.company_licence_navigate .swiper-button-next,
.company_licence_navigate .swiper-button-prev {
  display: none;
 
}
.company_licence-images {
  position: relative;
  height: 45rem;
  
}
.company_licence-images img {
  width: 100%;
  height: 45rem;
  object-fit: cover;
  border-radius: 10px;

  
}
.company_licence-details {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  padding: 3rem;
}
.company_licence-details h3 {
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 3.2rem;
  color: #ffffff;
}
.company_licence-details p {
  font-weight: 400;
  font-size: 2rem;
  line-height: 3rem;
  color: #b9b9b9;
  padding-top: 1.5rem;

}
.company_licence .swiper-slide{
    border: none;
}
@media screen and (max-width: 768px) {
  .company_licence_navigate .swiper-button-next,
  .company_licence_navigate .swiper-button-prev {
    display: block;
    position: absolute;
    width: calc(var(--swiper-navigation-size) / 44 * 27);
    height: var(--swiper-navigation-size);
    margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--swiper-navigation-color, var(--swiper-theme-color));
  }
}