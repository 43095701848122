.expand-business-content {
  display: flex;
  padding: 13.6rem 0;
  gap: 16.3rem;
  flex-wrap: wrap;
}

.expand-business-details .desc {
  padding: 3rem 0 6rem 0;
}
.expand-business-details {
  width: 45%;
}
.expand-business-icons {
  width: 42%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.expand {
  width: 33.333333%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.expand p {
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 21px;
  text-align: start;
  letter-spacing: -0.750316px;
  color: #000000;
  padding-top: 2.1rem;
  padding-left: 2rem;
}
.business {
  width: 11rem;
  height: 11rem;
  border-radius: 4rem;
  padding: 3rem;
}
.business img {
  width: 5rem;
}
.practical {
  background: linear-gradient(
    323.91deg,
    #ffcc40 7.09%,
    rgba(255, 204, 64, 0.5) 88.82%
  );
}
.respect {
  background: linear-gradient(
    320.89deg,
    #1ac0c0 10.83%,
    rgba(26, 192, 192, 0.5) 88.7%
  );
}
.excellence {
  background: linear-gradient(
    319.4deg,
    #0898e7 5.17%,
    rgba(8, 152, 231, 0.5) 94.34%
  );
}
.accountability {
  background: linear-gradient(
    322.63deg,
    #ff9066 9.94%,
    rgba(255, 144, 102, 0.5) 91.14%
  );
}
.team-work {
  background: linear-gradient(
    322.63deg,
    #fb3f6c 9.94%,
    rgba(251, 63, 108, 0.5) 91.14%
  );
}
@media screen and (max-width: 768px) {
  .expand-business-content {
    flex-direction: column-reverse;
    gap: 6rem;
  }
  .expand-business-details {
    width: 100%;
  }
  .expand-business-icons {
    padding-left: 3rem;
    width: 100%;
  }
  .business {
    width: 8.3rem;
    height: 8.3rem;
    border-radius: 2rem;
    padding: 2.4rem 2.5rem;
  }
  .business img {
    width: 3.1rem;
  }
  .expand p {
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.6rem;
    text-align: start;
    letter-spacing: -0.750316px;
    color: #000000;
    padding-top: 1.4rem;
    padding-left: 1.5rem;
  }
  .expand {
    margin: 2rem 0;
  }
  .expand-business-details .desc {
    padding: 3rem 0 4rem 0;
  }
}
