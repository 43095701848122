.company_overview {
  padding-top: 12rem;
}
.omv_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.omv_title {
  width: 50%;
}
.omv h1 {
  font-weight: 700;
  font-size: 45px;
  line-height: 53px;
  color: #282c4b;
  text-align: center;
  padding-bottom: 3.8rem;
}

.active {
  color: rgba(40, 44, 75, 1) !important;
}
.green_color {
  color: rgba(154, 187, 80, 1);
}
.omv {
  background: #ffffff;
  box-shadow: 0px 3.25028px 40.6285px rgba(0, 0, 0, 0.1);
  border-radius: 12.1886px;
}
.omv_icon {
  text-align: right;
}

.omv p {
  padding: 3.2rem 6.8rem 3.2rem 6.8rem;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.8rem;
  color: #666666;
  text-align: center;
}
.omv_description {
  width: 100%;
}
.omv_logo {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 10rem;
}
.logo_card {
  position: relative;
  cursor: pointer;
}
.logo_card:not(:last-child)::before {
  content: "";
  position: absolute;
  top: 5.6rem;
  left: 16.1rem;
  width: 26.6rem;
  border: 1px dashed #0f2137;
}
.logo_card p {
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.1rem;
  text-align: center;
  letter-spacing: -0.750316px;
  color: #0f2137;
  padding-top: 2.1rem;
}
.svg_fill_none {
  border: 2px solid #1ac0c0;
  padding: 3.5rem 3rem;
  border-radius: 4rem;
}
.svg_fill {
  background: linear-gradient(
    320.89deg,
    #1ac0c0 10.83%,
    rgba(26, 192, 192, 0.5) 88.7%
  );
  padding: 3rem 3rem;
  border-radius: 3.5rem;
}
.mission_svg_fill_none {
  border: 2px solid #ffcc40;
  padding: 3rem 3rem;
  border-radius: 3.5rem;
}
.mission_svg_fill {
  background: linear-gradient(
    323.91deg,
    #ffcc40 7.09%,
    rgba(255, 204, 64, 0.5) 88.82%
  );
  padding: 3rem 3rem;
  border-radius: 4rem;
}
.vission_svg_fill_none {
  border: 2px solid #0898e7;
  padding: 3rem 3rem;
  border-radius: 4rem;
}
.vission_svg_fill {
  background: linear-gradient(
    319.4deg,
    #0898e7 5.17%,
    rgba(8, 152, 231, 0.5) 94.34%
  );
  padding: 3rem 3rem;
  border-radius: 3.5rem;
}

.mobile_omv {
  display: none;
}
@media screen and (max-width: 768px) {
  .mobile_omv {
    display: block;
  }
  .omv_logo {
    display: none;
  }
  .omv_wrapper {
    display: none;
  }
  .mobile_omv_content p {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2.3rem;
    color: #666666;
    padding: 1.5rem;
  }
  .up {
    color: #9abb50;
    font-weight: bold;
  }
  .title_icon {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    background: #ffffff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-bottom: 1.5rem;
  }
  .title_icon h1 {
    font-weight: 700;
    font-size: 2, 5rem;
    line-height: 5.3rem;
    color: #b9b9b9;
  }
}
