.sister_concern {
  padding-top: 10rem;
  text-align: center;
  position: relative;
  padding-bottom: 40rem;
}

.sister_concern_content {
  background: #fdfdff;
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 3.5rem 7rem 5.5rem 7rem;
  max-width: 1026px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 16%;
  right:16%;
  border-top: 10px solid #0898e7;
}
.sister_concern_content p {
  padding-top: 4rem;
  font-weight: 500;
  font-size: 1.7rem;
  line-height: 2.8rem;
  text-align: start;
  color: #333333;
}
.sister_concern_content h2 {
  font-size: 3rem;
  line-height: 3.4rem;
  text-align: center;
  color: #282c4b;
}
.sg_lanka{
    color: #000000;
    font-weight:600;
}
@media screen and (max-width: 768px) {
  .sister_concern_img img {
    width: 100%;
    height: 254px;
    object-fit: cover;
    border-radius: 1rem;
  }
  .sister_concern_content {
    max-width: 302px;
    padding: 2rem 3rem 4rem 3rem;
    left: 13%;
    right:13%;
    top: 37%;
  }
  .sister_concern_content p {
    padding-top: 2.5rem;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.9rem;
    text-align: center;
    color: #333333;
  }
  .sister_concern_content h2 {
    font-size: 1.7rem;
    line-height: 2rem;
    text-align: center;
    color: #282c4b;
  }
  .sister_concern {
    padding-bottom: 40rem;
  }
}
