.home_banner_nav {
    position: relative;
}
.home_banner_nav .navbar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 12.64%, rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
}

.home_banner_nav .navbar-light .navbar-nav .nav-link{
    color: #B9B9B9;
}

.home_banner_nav .navbar-nav .nav-btn{
    border: 1px solid #ffffff;
}

.home_banner_nav .open_menu svg path{
    fill: #ffffff;
  }
