.necessary_link {
  padding-top: 13rem;
}
.necessary_link .desc {
  padding-top: 3rem;
  max-width: 954.22px;
}
.necessary_link_content {
  padding-top: 5.4rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.necessary_links {
  width: 50%;
}
.links {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
  align-items: center;
  padding-bottom: 4.5rem;
}
.links p {
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 2, 8rem;
  text-decoration-line: underline;
  color: #0898e7;
}
.links h3 {
  border: 1px solid rgba(8, 152, 231, 0.4);
  border-radius: 50%;
  padding: 2.2rem 3.2rem;
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 3.7rem;
  text-align: center;
  color: #0898e7;
}
.links:not(:last-child)::before {
  content: "";
  position: absolute;
  top: 8.6rem;
  left: 4.1rem;
  width: 2px;
  height: 4rem;
  background: rgba(8, 152, 231, 0.4);
}
.link_img {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: end;
}
.link_img img {
  width: 100%;
  object-fit: cover;
}

@media screen and (max-width: 768px) {
  .necessary_link_content {
    flex-direction: column;
  }
  .necessary_links {
    width: 100%;
  }
  .links {
    gap: 0.5rem;
  }
  .link_img {
    width: 100%;
  }
  .links p {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
  .links h3 {
    border: 1px solid rgba(8, 152, 231, 0.4);
    border-radius: 50%;
    padding: 0.3rem 0.8rem;
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2.1rem;
    text-align: center;
    color: #0898e7;
  }
  .links:not(:last-child)::before {
    top: 3.1rem;
    left: 1.4rem;
  }
}
