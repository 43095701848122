.our_clients {
  padding-top: 19.7rem;
}
.client_title .desc {
  padding-top: 2rem;
}
.client_title {
  width: 25%;
}

.our_clients_content_wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 7.8rem;
}
.logos {
  background-image: url("../../../../Assets/images/bg.png");
  background-repeat: no-repeat;
  background-size: 990px 700px;
  background-position: center;
  width: 69%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-content: center;
  padding: 21rem 12rem 21rem 3rem;
  gap: 2rem;
}
.client_logo {
  width: 7rem;
}
.client_logo img {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .our_clients {
    padding-top: 12.7rem;
  }
  .our_clients_content_wrapper {
    flex-direction: column;
    gap: 7.8rem;
}
.client_title {
  width: 100%;
  text-align: center;
}
.logos {
  background-image: url("../../../../Assets/images/bg.png");
  background-repeat: no-repeat;
  background-size: 379px 359px;
  background-position: center;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  padding: 8rem 1rem 16rem 0rem;
  gap: 2rem;
}
.client_logo {
  width: 7rem;
}
}