.ceo {
  margin-top: 15.1rem;
  background: #fdf4f4;
}
.ceo_info {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.ceo_details {
  width: 50%;
}
.ceo_details h1 {
  font-weight: 500;
  font-size: 4rem;
  line-height: 4.6rem;
  color: #282c4b;
}
.ceo_details h3 {
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.3rem;
  color: #9abb50;
  padding: 1rem 0 2.5rem 0;
}
.ceo_details p {
  max-width: 49.3rem;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 3.2rem;
  color: #666666;
}
.ceo_img {
  width: 50%;
  padding: 7.8rem 6.1rem;
}

.pic img {
  width: 100%;
}
.padding_left {
  padding-left: 12.1rem;
}
@media screen and (max-width: 768px) {
  .ceo_info {
    flex-direction: column-reverse;
  }
 .director .ceo_info {
    flex-direction: column;
  }
  .ceo_details {
    width: 100%;
    padding-top: 7.1rem;
    padding-bottom: 7.1rem;
  }
  .ceo_img {
    width: 100%;
    padding:0;
  
  }

  .padding_left {
    padding-left: 0;
  }
}
